@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutCreditCardForm {
    padding: 12px;
    border-bottom: 1px solid #C7C5C3;

    input {
        width: 100%;
        z-index: 1;
        background: #FAFAFA;
        padding: 10px;
        border: 2px solid #7F7E7D;
        border-radius: 8px;

        &:focus {
            border: 2px solid #000;
        }
    }

    .Field-Label {
        padding-block-end: 4px;
        color: var(--field-label-color);
        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    &-cardNumberField {
        span {
            position: absolute;
            inset-inline-end: 9px;
            inset-block-end: 0;
            width: 30px;
            z-index: 99;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }

    &-nameHolder,
    &-cardNumberField {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-block-start: 10px;
    }

    &-cvcField,
    &-dateField {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    &-dateAndCvc {
        display: flex;
        align-items: flex-start;
        column-gap: 1rem;
        justify-content: space-between;
        margin-block-start: 12px;
    }

    &-errorMsg {
        font-size: 12px;
        color: var(--primary-error-color);
        text-align: start;
    }

    &-Captcha {
        padding-block-start: 12px;
    }

    .Field_error {
        input {
            border-bottom: 1px solid var(--primary-error-color);
        }
    }
}
