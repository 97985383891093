@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --footer-totals-height: 0;
    --loader-scale: 1;
}

.CheckoutOrderSummary {
    $divider-border: 1px solid var(--input-border-color);

    background: #F9F6F4;
    border-radius: 8px;

    @include desktop {
        border: none;
        padding: 0;
        margin-block-end: 15px;
    }

    &-SeedslabelClone {
        background-color: #545CA0;
        border-radius: 24px;
        padding: 2px 8px 2px 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        display: flex;
        width: auto;
        height: 24px;
        margin-inline-start: 16px;

        @include mobile {
            width: auto;
            height: 24px;
        }
    }

    &-SeedslabelSeeds {
        background-color: #A0546F;
        border-radius: 24px;
        padding: 2px 8px 2px 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        margin-inline-start: 16px;
        display: flex;
        width: auto;
        height: 24px;

        @include mobile {
            width: auto;
            height: 24px;
        }
    }

    &-SeedslabelWrapper {
        border-left: 1px solid #EEEEEE;
    }


    &-Header {
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 24px 10px 24px;
        
        @include mobile {
            padding: 16px;
        }

        h2 {
            margin-block: 0;
            font-size: 20px;
            font-weight: 600;

            @include mobile {
                font-size: 20px;
            }
        }

        .CheckoutOrderSummary-viewBasketButton {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 4px;
            white-space: nowrap;
        }
    }

    .CountWrapper {
        padding-inline: 24px;

        @include mobile {
            padding-inline: 16px;
        }

        .Content {
            display: grid;
            border-bottom: 1px solid var(--primary-divider-color);

            &:not(:has(.FreebieCount)) {
                .ItemsCount {
                    padding-block: 14px;
                }
            }

            &:not(:has(.ItemsCount)) {
                .FreebieCount {
                    padding-block: 14px;
                }
            }

            .ItemsCount {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                padding-block: 14px 7px;
            }

            .FreebieCount {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                padding-block: 7px 14px;
                
                @include mobile {
                    font-size: 16px;
                }
        
                .price {
                    font-weight: 600;
                }
            }
        }
    }
    
    .ExpandableContent {
        @include mobile {
            padding: 0;
            margin: 0;
        }

        &.CartPage-Coupon {
            padding: 0 24px;

            @include mobile {
                padding: 0 16px;
            }

            .ExpandableContent {
                &-Button {
                    justify-content: center;
                    border-top: 1px solid #C7C5C3;
                    padding: 3rem 0;

                    @include mobile {
                        padding: 2rem 0;
                    }
    
                    svg {
                        display: none;
                    }
                }

                &-Heading {
                    font-size: 16px;
                    font-weight: 500;
                    text-decoration: underline;
                }

                &-Content {
                    &_isContentExpanded {
                        padding-block-end: 24px;

                        @include mobile {
                            padding-block-end: 16px;
                        }
                    }
                }
            }
        }

        &.CheckoutOrderSummary-ItemList {
            &.expanded {
                padding: 20px 16px;

                .ExpandableContent-Content_isContentExpanded {
                    .CheckoutOrderSummary-ItemsInCart {
                        margin-block-start: 0px;
                    }
                }
            }

            .ExpandableContent-Button {
                display: none;
            }

            @include mobile {
                padding: 14px;

                .ExpandableContent-Button {
                    display: flex;
                    padding: 0px;
                    justify-content: flex-start;
                    gap: 5px;

                    .ExpandableContent-Heading {
                        font-size: 14px;
                        color: #666564;

                        svg {
                            fill: #666564;
                        }
                    }
                }
            }
        }
    }

    &-ItemsInCart {
        display: flex;
        column-gap: 10px;
        font-size: 16px;
        text-transform: none;
        inset-block-start: 2px;
        font-weight: 500;
        margin-block: 20px 16px;

        .Heading {
            color: var(--color-black);
        }

        .qty {
            color: #a3a3a3;
        }
    }

    &-SummaryItem {
        justify-content: space-between;
        align-items: center;
        display: flex;
        padding: 12px 0;
        margin-block-end: 0;
        margin-block-start: 14px;
        padding-inline-start: 24px;
        padding-inline-end: 24px;
        padding-block: 0;
        

        @include mobile {
            padding-inline-start: 16px;
            padding-inline-end: 16px;
        }

        &:last-child {
            @include mobile {
                margin-block-end: 16px;
            }
            
            margin-block-end: 0px;
        }

        &::before {
            content: none;
        }

        &_withAppendedContent {
            flex-wrap: wrap;
            padding-block-start: 0;

            .CheckoutOrderSummary-Text {
                display: flex;
                flex-direction: row;
                gap: 7px;

                > span {
                    font-size: 14px;
                }
            }
        }

        &_isTotal {
            @include mobile {
                margin-block-start: 0px;
            }

            font-size: 16px;
            line-height: 24px;
            background: #D6EBCE;
            padding: 2rem 24px;
            margin-block-start: 14px;


            @include desktop {
                border-block-start: 1px solid var(--primary-divider-color);
            }

            p {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                white-space: nowrap;
            }

            div {
                line-height: 24px;
            }

            .CheckoutOrderSummary-Price {
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
    
    &-OrderItems {
        @include mobile {
            background-color: #F9F6F4;
        }

        h3 {
            text-align: start;
        }
    }

    &-GreenPointsWrapper {
        border-bottom: 1px solid var(--primary-divider-color);
        border-left: 0;
        border-right: 0;
        padding: 2rem 0;

        @include desktop {
            border-top: 1px solid var(--primary-divider-color);
        }

        .Field {
            margin-block-start: 16px;

            label {
                font-size: 14px;
            }
        }
    }

    &-Points {
        font-size: 16px;
        font-weight: 500;

        span:last-child {
            color: var(--primary-base-color);
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &-CartItemList {
        list-style: none;
        margin: 0;
        padding: 0;

        .ExpandableContentShowMore {
            &-ShowAllButton {
                --button-background: transparent;

                margin: 0;                
                padding: 8px 0;
            }
        }

        .CartItem {
            margin-block-end: 10px;

            &-Picture {
                width: 76px;
                height: 76px;
                align-self: auto;
            }

            &-CartItemRows {
                .CartItem {
                    &-Heading {
                        font-size: 16px;
                        text-transform: capitalize;
                    }

                    &-DeliveryDate {
                        margin-block-start: 5px;
                        font-size: 12px;
                    }

                    &-ProductInfo {
                        padding: 0 12px;
                        border-bottom: none;

                        @include mobile {
                            padding: 0;
                        }
                        
                        .CartItem-Options {
                            display: none;
                        }

                        .CartItem-Heading {
                            font-size: 14px;
                            font-weight: 600;
                            width: 100%;
                            max-width: 200px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            min-height: unset;
                        }
                    }

                    &-Title {
                        .CartItem-Options {
                            display: block;
                        }
                    }

                    &-ProductActions {
                        padding-inline: 12px;

                        @include mobile {
                            display: flex;
                            padding-inline: 0;
                        }

                        .CartItem-Quantity {
                            margin-block-end: 0px;
                            font-size: 14px;
                        }

                        .CartItem-Price {
                            display: block;
                            font-weight: 600;
                            font-size: 16px;

                            .PromoPrice {
                                align-items: center;

                                del {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-CartItemDescription {
        margin-block-start: 5px;

        p {
            font-size: 13px;
            line-height: 1.5;

            @include mobile {
                font-size: 15px;
            }
        }
    }

    &-CartItem {
        display: grid;
        grid-template-columns: 96px auto auto;
        grid-column-gap: 6px;
        margin: 0 0 6px;
        padding: 0;

        @include mobile {
            grid-template-columns: 112px auto auto;
            grid-column-gap: 7px;
            margin: 0 0 7px;
        }

        &::before {
            content: none;
        }

        > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-Thumbnail {
        width: 96px;
        height: 96px;

        @include mobile {
            width: 112px;
            height: 112px;
        }
    }

    &-Title {
        p {
            margin: 0;
            padding: 0;
            text-align: start;
        }
    }

    &-Details {
        justify-self: end;
    }

    &-ExpandableContent {
        border-block-start: none;
        padding: 1.5rem;
    }

    &-ExpandableContentHeading {
        font-size: 18px;

        @include mobile {
            font-weight: 500;
        }
    }

    .ExpandableContent-ToggleButton {
        height: 20px;
        width: 20px;
        inset-inline-start: 3px;
    }

    &-Coupon {
        @include mobile {
            padding: 10px 14px;
            border-block-end: $divider-border;
            margin: 0 -14px;
            width: auto;
        }
    }

    &-CmsBlock {
        padding: 10px 0;
    }

    .ProductPrice {
        margin-block-start: 0;
        font-weight: normal;
        line-height: 1;
        font-size: 18px;

        &-SubPrice {
            display: block;
            font-size: 12px;
        }
    }

    .CartItem-Wrapper {
        padding: 12px;
        border-radius: 16px;
        grid-template-columns: 76px auto;

        @include mobile {
            padding: 14px;
            border-radius: 5px;
        }
    }

    &-Text {
        flex-direction: column;
        word-break: break-word;
        margin-block-end: 0;
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        text-transform: capitalize;
        opacity: 1;

        span {
            display: block;
            font-size: 12px;
            font-weight: 400;
        }
    }

    &-Price {
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: end;
        white-space: nowrap;
        font-size: 16px;
        flex-basis: 30%;
        font-weight: 600;

        span {
            line-height: 16px;
        }

        @include mobile {
            font-size: 16px;
        }
    }

    &-AppendedContent {
        flex-basis: 100%;
        font-size: 12px;
    }

    &-ButtonWrapper {  
        @include mobile {
            --footer-totals-height: 80px;

            width: 100%;
            inset-block-end: var(--footer-total-height);
            background-color: var(--color-white);
            border-block-start: none;
            position: unset;
            height: unset;
            transition: unset;
            z-index: unset;
            transform: none;
            padding: 0;

            .hideOnScroll & {
                transform: translateY(var(--footer-nav-height));
            }
        }

        &_isEmpty {
            display: none;
        }

        .CartNotServiceable {
            background-color: #fff4f4;
            padding: 2rem;
            margin: 2rem 0;

            &-Message {
                white-space: break-spaces;
                color: var(--primary-error-color);
            }
        }
    }

    .Loader {
        &-Scale {
            @media screen {
                inset-block-start: calc(35% - (3px * var(--loader-scale)));
            }
        }
    }

    &-Insurance {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Price {
            font-size: 16px;

            @include mobile {
                font-size: 14px;
            }
        }

        span:nth-child(2) {
            font-size: 16px;
            letter-spacing: 0px;
            color: #000000;
            text-transform: capitalize;
            opacity: 1;
        }

        span {
            width: fit-content;
        }
    }

    &-Inner {
        display: flex;
        align-items: center;

        input {
            width: 10px;
        }

        p {
            color: #000000;
            text-transform: capitalize;
            opacity: 1;
            font-size: 16px;
            margin-block-end: unset;

            @include mobile {
                font-size: 14px;
            }
        }

        .Field {
            margin-block-start: unset;
        }
    }

    &-OrderTotalincludeTax {
        display: flex;
        justify-content: space-between;

        .price {
            text-align: end;
        }

        span {
            font-size: 16px;
            text-transform: capitalize;
            opacity: 1;

            @include mobile {
                font-size: 14px;
            }
        }
    }

    &-OrderTotalExclusiveTax {
        display: none;

        .price {
            text-align: end;
        }


        @include mobile {
            justify-content: space-between;
            padding: 20px 16px;
            background: #D6EBCE;
        }

        span {
            font-size: 16px;
            text-transform: capitalize;
            opacity: 1;
            font-weight: 600;
        }
    }

    &-OrderTotals {
        ul {
            display: grid;
            
            .CartAdditionalFees {
                padding-inline: 24px;

                @include mobile {
                    padding-inline: 16px;
                }
            }
        }
    }

    &-DeliveryInsurance {
        @include mobile {
            padding-block-end: 14px;    
        }

        .AdditionalFee-Expandable {
            padding: 1rem 0;
            border-bottom: 1px solid #c5c5c5;
            border-top: 1px solid #c5c5c5;

            .ExpandableContent-Button {
                display: flex;
            }

            .ExpandableContent-Content {
                overflow: initial;
            }
        }

        .Fees {
            .Fee {
                padding: 0;
                margin-block-end: 14px;

                .FieldSelect-Select {
                    padding-inline-start: 10px;
                }
            }
        }

        .CartAdditionalFees {
            padding: 0;

            &-Option {
                padding: 5px 0;

                @include desktop {
                    height: 45px;
                    align-items: center;
                }

                @include mobile {
                    height: 40px;
                    align-items: flex-end;
                }
            }
        }
    }

    &-SafetyConcern {
        display: flex;
        flex-direction: column;
        margin: 2rem;
        align-items: center;

        .CheckoutOrderSummary {
            &-Sheild {
                height: 20px;
                width: 16px;
            }
        }

        p {
            letter-spacing: 0px;
            opacity: 1;
            font-size: 12px;
            font-weight: 300;
            margin: 0;
            white-space: break-spaces;
        }
    }

    span.label {
        @include mobile {
            background-color: transparent;
            width: 100%;
        }
    }
}
