@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutDeliveryOption {
    padding: 16px;
    max-width: 450px;
    width: 100%;
    border: 2px solid #9F9D9C;
    border-radius: 8px;

    @include mobile {
        padding: 12px 16px;
    }

    &-Button {
        align-items: center;
    }

    &:first-child {
        border-top: 2px solid #9F9D9C;
    }

    &_isActive:first-child {
        border-top: 2px solid var(--color-black);
    }

    &_isActive {
        border: 2px solid var(--color-black);
    }

    &-Message {
        margin-block-start: 1rem;
    }
}
