@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CheckoutAddressBook {
    @include mobile {
        margin: 24px 0;
    }

    .Checkout {
        display: block;

        &-Heading {
            font-size: 18px;
            font-weight: 600;
            margin: 0;

            @include mobile {
                font-size: 16px;
            }
        }

        &-Default {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #F9F6F4;
            padding: 16px;
            border-radius: 8px;

            button {
                font-size: 14px;
                font-weight: 600;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: var(--primary-base-color);
                }
            }
        }

        &-Custom {
            .Checkout-Heading {
                border-bottom: 1px solid #c5cbd5;
                padding-block-end: 2rem;
            }
        }
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            margin-block-end: 24px;
        }
    }

    .CheckoutAddressBook {
        &-CustomAddressWrapper {
            display: grid;
            margin: 30px 0 24px 0;

            @include mobile {
                margin: 20px 0;
            }

            h4 {
                font-size: 20px;
                font-weight: 600;

                @include mobile {
                    font-size: 16px;
                }
            }

            .TopContent {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .TopContent-sub .POLabel {
                    color: red;
                    display: block;
                    margin-block: 0.5rem;
                }
            }

            .CheckoutAddressBook-Button {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                color: var(--color-black);
                cursor: pointer;
                border: none;
                column-gap: 5px;

                &:hover {
                    color: var(--primary-base-color);

                    svg {
                        path {
                            fill: var(--primary-base-color);
                        }
                    }
                }

                span {
                    text-transform: capitalize;
                }

                @include mobile {
                    font-size: 12px;
                    flex-basis: 50%;
                }
            }
        }

        &-Button {
            &_isCustomAddressExpanded {
                display: block;
            }
        }
    }

    .FieldForm-Fields, .FieldGroup {
        row-gap: 2rem;

        @include wide-desktop {
            grid-column-gap: 6rem;

            > *:last-child:nth-child(odd) {
                grid-column: span 1;

                label {
                    font-size: 14px;
                    color: #6F6F6F;
                }
            }
        }
    }

    .FieldGroup {
        &-Wrapper {
            &_multipleFields.FieldGroup-Wrapper_oddAddresses {
                @include wide-desktop {
                    .FieldGroup {
                        > *:first-child {
                            grid-column-end: unset;
                        }
                    }
                }
            }

            &_street {
                .Field-Wrapper {
                    &:nth-child(n+2) {
                        display: none;
                    }
                }
            }
        }
    }

    .Button_block_CheckoutAddressBook {
        width: 100%;
        max-width: 325px;
        border-radius: 8px;

        @include mobile {
            max-width: 100%;
        }

        &:hover {
            border-radius: 8px;
        }
    }

    .StoreSwitcherV2 {
        button {
            width: 100%;
            max-width: 325px;
            border-radius: 8px;

            &:not([disabled]):hover {
                border-radius: 8px;
            }
        }
    }
}
